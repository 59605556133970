html {
    scroll-behavior: smooth; 
}
body {
  font-family: 'Mark', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
}

* {
    box-sizing: border-box;
}

#root {
    background-color:#FFF
}
.container {
    margin: auto;
    max-width: 1200px;
    padding: 2em 10px 10px 10px;
    background-color:#FFF;
    min-height: calc(100vh - 82px);
}

.footer-container {
    z-index: 1201;
    position: relative;
    margin:auto;
    height: fit-content;    
    padding: 8px 0px;
    background-color: #FFF;        
    font-weight: bold;
    color:#5C667A;      
}

.react-csv-input {
    display: none;
}

.info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 0px 10vw;
    height: calc(100vh - 200px);
    text-align: center;
    transform:translate(0, -10%);    
}


@keyframes slow-float {
    from {margin-top: 0;margin-bottom: 0;}
    to {margin-top: -10;margin-bottom: 10;}
  }


  
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    margin:auto;    
    height: 0;    
    /* max-width: 788px; */
  }
  
  .video-responsive iframe {
    left: 1%;
    top: 1%;
    height: 98%;
    width: 98%;
    position: absolute;
  }


/* CSS loading animation */
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #B5C7C6;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
/* CSS loading animation */










.MuiAppBar-colorPrimary {
  background-color: #dcff57 !important;
}


.MuiButton-outlined {
  border-color: #0b002b !important;
  color: #0b002b !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #0b002b !important;
}




.MuiInput-underline::after {
  border-bottom-color: #0b002b !important;
}


.MuiChip-outlined.MuiChip-outlinedPrimary > svg > path {
  fill: #0b002b !important;
}

.MuiChip-outlined.MuiChip-outlinedPrimary {
  border-color: #0b002b !important;
}

.MuiChip-label {
  color: #0b002b !important;
}




.MuiButton-containedPrimary {
  color: #0b002b !important;
  background-color: #dcff57 !important;
}

.MuiCheckbox-colorPrimary,
.MuiCheckbox-colorPrimary.Mui-checked,
.MuiRadio-colorPrimary,
.MuiRadio-colorPrimary.Mui-checked {
  color: #0b002b !important;
}

.MuiFormLabel-colorPrimary {
  color: #0b002b !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: #dcff57 !important;
  color: #0b002b !important;
}

.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel > div {
  background-image: none;
  background-color: #a8b7ff !important;
  color: #0b002b;
  box-shadow: 1px 3px 5px 2px #efefef;
}

.MuiStepLabel-horizontal.Mui-disabled .MuiStepLabel-iconContainer > div {
  background-image: none;
  background-color: #efefef !important;
  color: #ccc;
  box-shadow: none;
}



.MuiStepConnector-line.MuiStepConnector-lineHorizontal {
  background-color: #ccc !important;
}

.MuiStepConnector-horizontal.Mui-active > span {
  background-image: linear-gradient(95deg,#dcff57 0%,#dcff57 50%,#dcff57 100%) !important;
}

.MuiTypography-root.MuiTypography-h4 {
  color: #0b002b !important;
}

.MuiInputBase-colorPrimary .MuiOutlinedInput-input:focus+.MuiOutlinedInput-notchedOutline {
  border-color: #0b002b !important;
}

.MuiFab-circular.MuiFab-primary {
  background-color: #dcff57;
  color: #0b002b;
}

.MuiFab-circular.MuiFab-primary:hover {
  background-color: #bada48;
  color: #0b002b;
}

.MuiDataGrid-toolbarContainer button {
  color: #0b002b;
}

.MuiLinearProgress-colorPrimary {
  background-color: #bada48 !important;
}

.MuiLinearProgress-colorPrimary > span:first-child {
  background-color: #0b002b !important;
}

.MuiLinearProgress-colorPrimary > span:last-child {
  background-color: #0b002b !important;
}


.MuiButton-text > svg[data-testid="CloseIcon"] > path {
  fill: #0b002b !important;
}